import React from 'react';

import './style.css';
import Navigation from '../navigation';
import Footer from '../footer';



class Developer extends React.Component {
  render() {
    return(
      <div className='development'>
        <Navigation />
        <div className='firstSite'>
          <h1>Developer: Paul Dunz</h1>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Developer;
