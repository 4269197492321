import React from 'react';

import './style.css';

import Footer from '../footer';

import background_iphone from '../../assets/img/mobilePhone.png';
import bottomPart from '../../assets/img/bottom-part.png';
import firstIphone from '../../assets/img/iphone1.png';
import secondIphone from '../../assets/img/iphone2.png';
import thirdIphone from '../../assets/img/iphone3.png';
import scrollDownButton from '../../assets/img/firstSiteScrollDown.png';
import appstoredownload from '../../assets/img/appstoredownload.png';
import instagramicon from '../../assets/img/instagramicon.png';
import twittericon from '../../assets/img/twittericon.png';
import linkedinicon from '../../assets/img/linkedinicon.png';
import circle from '../../assets/img/circle.png';
import rightCircle from '../../assets/img/rightCircle.png';
import topPart from '../../assets/img/top-part.png';
import profile from '../../assets/img/profilePicture.png';
import configureGame from '../../assets/img/configureGame.png';
import downloadApp from '../../assets/img/downloadApp.png';
import mobileAboutBackground from '../../assets/img/about-part.png';
import Navigation from '../navigation';


class Home extends React.Component {
  render() {
    return(
      <div className='home'>
        <Navigation />
        <h1 id='mobileTitle'>Home</h1>
        <div className='firstSite'>
          <div className='topPart'>
            <div className='text'>
              <h1>Wage das Risiko, <br />hab’ Spaß - mit RiskyLife!</h1>
              <p>RiskyLife ist die ultimative Party-App für alle, die nach Aufregung und Abenteuer suchen. Mit einer Mischung aus mutigen Pflichten und wahrheitsgemäßen Offenbarungen ist diese App perfekt für diejenigen, die bereit sind, ihre Komfortzone zu verlassen und das Risiko einzugehen.</p>
            </div>
            <div className='backgroundIphone'>
              <img src={background_iphone} alt='background-iphone'></img>
            </div>
          </div>
          <div className='whiteBorderLine'>
            <img src={bottomPart}></img>
          </div>
          <a href='#fourthSite'><img id='scrollDownButton' src={scrollDownButton} alt='scroll-down-button'></img></a>
        </div>
        <div id='secondSite' className='secondSite'>
          <div className='iphones'>
            <img id='firstIphone' src={firstIphone}></img>
            <img id='secondIphone' src={secondIphone}></img>
            <img id='thirdIphone' src={thirdIphone}></img>
          </div>
          <div className='availableIcons'>
            <div className='appstore'>
              <img src={appstoredownload}></img>
            </div>
            <div className='socialMediaLinks'>

              <a href="https://instagram.com/riskylife.app">
                <div>
                  <img id='instagramIcon' src={instagramicon}></img>
                </div>
              </a>
              <a href="https://twitter.com/pauldunz">
                <div>
                  <img id='twitterIcon' src={twittericon}></img>
                </div>
              </a>
              <a href="https://de.linkedin.com/in/paul-dunz-5ba797222">
                <div>
                  <img id='linkedinIcon' src={linkedinicon}></img>
                </div>
              </a>

            </div>
          </div>
          <div className='circles'>
            <img id='circle1' src={circle}></img>
            <img id='circle2' src={rightCircle}></img>
          </div>
        </div>
        <div className='thirdSite'>
          <h1>Über mich</h1>
          <div className='blueBorderLine'>
            <img src={topPart}></img>
          </div>
          <div className='aboutPart'>
            <img src={profile}></img>
            <div className='profileText'>
              <h1>-The man behind! <br />Paul</h1>
              <p>Ich bin ganze 19 Jahre jung und widme mich derzeit dem Studium der Informatik. Bereits im zarten Alter von 13 Jahren begann ich meine Leidenschaft für das Programmieren zu entdecken. Seitdem habe ich mit Begeisterung zahlreiche Projekte umgesetzt, darunter auch diese App. Ursprünglich hatte ich lediglich vor, eine neue Programmiersprache zu erlernen. Doch wie erlangt man am besten Kenntnisse in einer neuen Sprache? <br />Indem man sie praktisch anwendet. <br />Daher war ich auf der Suche nach einem passenden Projekt. Die ursprüngliche Idee hinter dieser App war eine gänzlich andere. Nun, nach etwa einem Jahr, ist das Projekt vollendet und ich habe nicht nur eine neue Programmiersprache erlernt, sondern auch eine funktionale App geschaffen.</p>
            </div>
          </div>
        </div>
        <div id='fourthSite' className='fourthSite'>
          <h1>Spielablauf</h1>
          <div className='gameplayIntroduction'>
            <div className='text'>
              <h1>Konfiguriere dein Spiel!</h1>
              <p>Bevor das Spiel beginnen kann, müssen die Anzahl der Spieler und ihre Namen festgelegt werden. Es steht den Spielern frei, den gewünschten Schwierigkeitsgrad der Fragen/Aufgaben im Spiel zu wählen. Darüber hinaus besteht die Möglichkeit, mehrere Kategorien auszuwählen, um das Spiel abwechslungsreicher und spannender zu gestalten. Nachdem der Play-Button gedrückt wurde, bietet sich die Möglichkeit, die Runden festzulegen und eventuell Strafen in das Spiel zu integrieren. Natürlich kann das Spiel auch ohne Strafen gespielt werden, doch es wird dringend empfohlen, zu Beginn des Spiels fesselnde Strafen festzulegen, um jeden Spieler dazu zu motivieren, nicht zu verlieren. Am Ende muss der Verlierer die zu Beginn festgelegten Strafen mit einem zufälligen Faktor multipliziert ausführen. </p>
            </div>
            <img src={configureGame}></img>
          </div>
          <div className='whiteBorderLine'>
            <img src={bottomPart}></img>
          </div>
        </div>
        <div className='fifthSite'>
          <div className='download'>
            <img src={downloadApp}></img>
            <div className='downloadInformation'>
              <h1>Download <br />App</h1>
              <p>Die Spiel ist aktuell nur im AppStore von Apple verfügbar. Die App bietet In-App Abonnements an, um Zugriff auf alle Premium Spieloptionen zu erhalten.</p>
              <img id='appstoreIcon' src={appstoredownload}></img>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;