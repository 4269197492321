import React from 'react';

import './style.css';

import Navigation from '../navigation';
import Footer from '../footer';

import contactPageBackground from '../../assets/img/contactBackground.png';;



class Contact extends React.Component {
  render() {
    return(
      <div className='contact'>
        <Navigation />
        <div className='firstSite'>
          <h1>Kontakt</h1>
          <img className='contactBackground' src={contactPageBackground}></img>
          <div className='text'>
            <p>Bei Problemen oder Fragen zu der App, wenden Sie sich bitte an diese Mail:</p>
            <a href='mailto:contact@riskylife.app'>
              <div className='mailContainer'>
                <p>contact@riskylife.app</p>
              </div>
            </a>
            <p>Bei Ideen zu Spielerweiterungen:</p>
            <a href='mailto:gameideas@riskylife.app'>
              <div className='mailContainer'>
                <p>gameideas@riskylife.app</p>
              </div>
            </a>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
