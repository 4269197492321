import React from 'react';
import { Link } from "react-router-dom";
import icon_navbar from '../../assets/img/icon-navbar.png';

import './style.css';

function openMenu() {
  document.getElementById("toggle-navigation").classList.toggle("active");
}



class Navigation extends React.Component {
  render() {
    return(
      <nav>
        <div className='mobile-navigation-icon'>
            <a className="icon" onClick={openMenu}>
              <i className="fa fa-bars"></i>
              <i className="fa fa-bars"></i>
              <i className="fa fa-bars"></i>
            </a>
          </div>
        <img className='icon-navbar' src={icon_navbar} alt='app-icon'></img>
        <h2>RiskyLife</h2>
        <div className='navigation'>
          <div className="navigation-links" id='toggle-navigation'>
            <ul>
              <li onClick={this.handleLinkClick}><Link className="navLinks" to="/home">Home</Link></li>
              {/* <li onClick={this.handleLinkClick}><Link className="navLinks" to="/developer">Entwicklung</Link></li> */}
              <li onClick={this.handleLinkClick}><Link className="navLinks" to="/contact">Kontakt</Link></li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
