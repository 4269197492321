import React from 'react';

import icon_footer from '../../assets/img/icon-footer.png';

import './style.css';
import { Link } from 'react-router-dom';


class Footer extends React.Component {
  render() {
    return(
      <div className='footer'>
        <div className='topBar'></div>
        <div className='links'>
            <div>
                <p>Navigation:</p>
            </div>
            <div className='navigation'>
                <ul>
                    <li><Link onClick={window.scrollTo(0, 0)} to='/home'>Home</Link></li>
                    {/* <li><Link onClick={window.scrollTo(0, 0)} to='/developer'>Entwicklung</Link></li> */}
                    <li><Link onClick={window.scrollTo(0, 0)} to='/contact'>Kontakt</Link></li>
                </ul>
            </div>
            <div>
                <p>Rechtliches:</p>
            </div>
            <div className='rights'>
                <ul>
                    <li><a href='https://www.iubenda.com/terms-and-conditions/27039424'>AGB</a></li>
                    <li><a href='https://www.iubenda.com/privacy-policy/27039424'>Datenschutz</a></li>
                    <li><a href='https://riskylife.app/impressum/index.html'>Impressum</a></li>
                </ul>
            </div>
        </div>
        <img src={icon_footer}></img>
      </div>
    );
  }
}

export default Footer;
