import './App.css';

import { BrowserRouter as Router, Route, BrowserRouter } from 'react-router-dom';
import { Navigate, Routes } from 'react-router';

import Home from './navpages/home';
import Developer from './navpages/developer';
import Contact from './navpages/contact';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/developer" element={<Developer />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
